<script setup lang="ts">
const colorMode = useColorMode();

const isDark = () => {
  return colorMode.value === 'dark';
};

function handleThemeToggle() {
  colorMode.value = colorMode.value === 'dark' ? 'light' : 'dark';
}
</script>

<template>
  <UIToolbarToggleButton class="theme-toggle-button" @click="handleThemeToggle">
    <div class="theme-icon-container">
      <Transition
          enter-active-class="theme-appear-transition"
          enter-from-class="theme-appear-transition-enter-from"
          enter-to
          enter-to-class="theme-appear-transition-enter-to"
          leave-active-class="theme-leave-transition"
          leave-from-class="theme-leave-transition-from"
          leave-to-class="theme-leave-transition-to"
      >
        <IconMoon v-if="isDark()" class="theme-icon"/>
        <IconSun v-else class="theme-icon"/>
      </Transition>
    </div>
  </UIToolbarToggleButton>
</template>

<style>
.theme-toggle-button {
  overflow: hidden;
}
.theme-icon {
  color: rgb(var(--tw-color-yellow-400));
  width: 1.25rem;
  height: 1.25rem;
}

.theme-icon-container {
  width: 20px;
  height: 20px;
}

.theme-appear-transition, .theme-leave-transition {
  transition-property: transform;
  transition: .3s ease-in;
}

.theme-appear-transition {
  transition-delay: .3s;
}

.theme-appear-transition-enter-from {
  transform: rotate(-45deg) translateX(-50px) rotate(45deg);
}

.theme-leave-transition-to {
  transform: rotate(45deg) translateX(50px) rotate(-45deg);
}
</style>